import "./Confirm.css"

import React, { useCallback, useMemo, useState } from 'react'

import Input from '../input/Input'
import useConfirm from '../hooks/UseConfirm'

const ConfirmSingle = ({ token, id, message: savedMessage = '', guests: [{ id: guestId, name, surname, confirmed: savedConfirmed = false }], refresh }) => {
  const [confirmed, setConfirmed] = useState(savedConfirmed)
  const [message, setMessage] = useState(savedMessage)

  const onEnd = useCallback((value) => setMessage(value), [setMessage])

  const updated = useMemo(() => ({ id, message, guests: [{ id: guestId, confirmed }] }), [guestId, id, message, confirmed])
  const changed = useMemo(() => confirmed !== savedConfirmed || message !== savedMessage, [confirmed, savedConfirmed, message, savedMessage])

  const [saving, error, persist] = useConfirm(refresh, token)

  const changeConfirmed = useCallback(() => setConfirmed(!confirmed), [confirmed, setConfirmed])
  const save = useCallback(() => persist(updated), [updated, persist])

  if (error) {
    localStorage.removeItem("surname")
  }

  return (
    <div className="confirm-box">
      <h2 className="name">{name} {surname} <strong style={{ color: savedConfirmed ? "lightgreen" : "orange" }}>{savedConfirmed === null ? "?" : savedConfirmed ? "✓" : "✘"}</strong></h2>

      <div style={{ display: "flex", flexWrap: "wrap" }} className="content">
        STATUS:
        <strong style={{ paddingLeft: 8, paddingRight: 8, color: savedConfirmed ? "green" : "orange" }}>
          {
            confirmed ? "POTWIERDZONY" : "NIEPOTWIERDZONY"
          }
        </strong>
        <div style={{ marginLeft: "auto" }}>
          <button style={{ color: confirmed ? "orange" : "green" }} className="send confirm" onClick={changeConfirmed}>
            {
              confirmed ? "ZREZYGNUJ" : "POTWIERDŹ"
            }
          </button>
        </div>
      </div>

      <div className="message-box">
        <small>opcjonalna wiadomość/uwagi:</small>
        <Input multiline rows={8} className="message" style={{ borderRadius: 4, border: "3px solid #2c2c2c" }} placeholder="Zacznij pisać" initial={savedMessage} onEnd={onEnd} />
      </div>

      <div className="decide">
        <strong style={{ color: saving ? "gray" : !changed ? "green" : "orange" }}>
          {
            saving && changed ? "Zapisywanie zmian..." : changed && !saving ? "Zmieniono szczegóły zaproszenia." : !changed && !saving && "Zapisano."
          }
        </strong>
        <button className="send confirm" onClick={save}>ZAPISZ ZMIANY</button>
      </div>

      <div>
        {
          error && (
            <strong style={{ color: "red" }}>Coś poszło nie tak: {error}</strong>
          )
        }
      </div>
    </div>
  )
}

export default ConfirmSingle