import './Invitation.css'

import { useHistory, useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'

import Auth from '../auth/Auth'
import ConfirmMultiple from '../confirm/ConfirmMultiple'
import ConfirmSingle from '../confirm/ConfirmSingle'
import useInvitationData from '../hooks/UseInvitationData'

const Invitation = () => {
  let { id } = useParams()
  if (id) id = id.toLowerCase()
  const [surname, setSurname] = useState(decodeURIComponent(localStorage.getItem("surname") || '') || '')
  const { push } = useHistory()
  const [loading, invitation, fetchError, refresh] = useInvitationData(id, surname)

  const showInvitation = useMemo(() => surname && !loading && invitation && id === invitation.id, [surname, loading, invitation, id])

  if (showInvitation && surname) {
    localStorage.setItem("surname", encodeURIComponent(surname))
  }

  if (fetchError) {
    localStorage.removeItem("surname")
  }

  return (
    <>
      <button className="fade delay-1 send" style={{
        margin: "10px auto 10px 10px"
      }} onClick={() => push("/")}>
        strona główna
      </button>
      <div className="invitation">
        <div className="frame fade delay-1">
          {
            showInvitation ? (
              <>
                {
                  invitation.single ? (
                    <ConfirmSingle token={surname} refresh={refresh} {...invitation} />
                  ) : (
                    <ConfirmMultiple token={surname} refresh={refresh} {...invitation} />
                  )
                }
              </>
            ) : !surname ? (
              <Auth onSubmit={setSurname} />
            ) : loading && (
              <div className="lds-ripple"><div></div><div></div></div>
            )
          }
          {
            !!fetchError && (
              <div className="error">
                <strong className="error-text">{fetchError.message ? fetchError.message : fetchError}</strong>
                <button onClick={() => push("/")} className="send">
                  wróć
              </button>
              </div>
            )
          }
        </div>
        <p className="notice fade delay-1">
          <small>W razie problemów ze stroną obecność powierdzić można dzwoniąc do mnie na nr: <strong>+48 690 537 929</strong></small>
          {
            !fetchError && !!invitation && surname && (
              <span className="material-icons">expand_more</span>
            )
          }
        </p>
      </div>
      {
        !fetchError && !!invitation && surname && (
          <>
            <div className="invitation-description">
              <div className="centered">
                <h1>Informacje</h1>
                <p>
                  Przyjęcie odbędzie się przy ul. <strong>Wierzbica 9 w Serocku</strong>, i będzie trwać do godziny <strong>02:00</strong>.
              </p>
                <p>
                  W razie problemów z transportem, prosimy o kontakt telefoniczny, lub informacje w formularzu powyżej.
              </p>
                <p>
                  Ponieważ ceremonia ślubu kończy się dosyć późno, zachęcamy aby nie składać nam życzeń przed kościołem, ale <strong>od razu kierować się na przyjęcie</strong>.
              </p>

                <p>
                  <strong>
                    💗 Bardzo cieszymy się że możemy razam z Wami świetować, i dzielić się naszą radością! 💗
                </strong>
                </p>
              </div>
            </div>
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2427.0973173844395!2d21.06772331744385!3d52.5316734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ebff8c036aea5%3A0xf3972a95208c54ad!2sZ%C5%82oty%20Lin!5e0!3m2!1sen!2spl!4v1619347665052!5m2!1sen!2spl" width="600" height="450" style={{ border: 0, margin: 0, width: "100%" }} allowFullScreen="" loading="lazy"></iframe>
          </>
        )
      }
    </>
  )
}

export default Invitation
