import { useCallback, useState } from "react"

import { BASE_URL } from "../const"
import fetch from "../util/fetch"

const MESSAGES = {
  403: "Brak pozwolenia",
  404: "Nie znaleziono zaproszenia",
  401: "Nie masz uprawnien by zobaczyć tę stronę",
  400: "Nie znaleziono zaproszenia",
  500: "Wystąpił problem. Proszę, potwierdź swoją obecność telefonicznie na numer: 690 537 929",
  0: "Nie udało się nawiązać połączenia"
}

const useConfirm = (refresh, token) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const persist = useCallback((updated) => {
    setLoading(true)

    const [xhr, execute] = fetch("POST", `${BASE_URL}/invitation/${updated.id}`)

    execute({
      headers: {
        'Content-Type': "application/json",
        'X-AUTH-TOKEN': `${updated.id}:${encodeURIComponent(token)}`
      },
      body: updated
    })
      .then(data => {
        setError('')
        refresh(data)
      })
      .catch(error => {
        setError(MESSAGES[error])
      })
      .then(() => setLoading(false))

    return () => xhr.abort()
  }, [token, setError, setLoading, refresh])

  return [loading, error, persist]
}

export default useConfirm