const fetch = (method, url) => {
  const xhr = new XMLHttpRequest()

  const execute = ({ body, headers }) => new Promise((resolve, reject) => {
    xhr.open(method, url, true)

    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key])
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText))
      } else {
        let error = xhr.status

        try {
          const parsed = JSON.parse(xhr.responseText)
          if ("error" in parsed) error = parsed.error
        } catch { }

        reject(error)
      }
    }
    xhr.onerror = () => {
      let error = xhr.status

      try {
        const parsed = JSON.parse(xhr.responseText)
        if ("error" in parsed) error = parsed.error
      } catch { }

      reject(error)
    }
    xhr.onabort = () => reject("aborted")

    xhr.send(body ? JSON.stringify(body) : null)
  })

  return [xhr, execute]
}

export default fetch