import { useCallback, useEffect, useState } from "react"

import { BASE_URL } from "../const"
import fetch from "../util/fetch"

const MESSAGES = {
  403: "Brak pozwolenia",
  404: "Nie znaleziono zaproszenia",
  401: "Nie masz uprawnien by zobaczyć tę stronę",
  400: "Nie znaleziono zaproszenia",
  500: "Wystąpił problem. Proszę, potwierdź swoją obecność telefonicznie na numer: 690 537 929",
  0: "Nie udało się nawiązać połączenia"
}

const useInvitations = (id, token) => {
  const [invitations, setInvitationList] = useState()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const refresh = useCallback((invitations) => {
    if (id && token) {
      if (invitations) {
        setInvitationList(invitations)
        setError('')
      } else {
        setLoading(true)
        const [xhr, execute] = fetch("GET", `${BASE_URL}/invitations`)

        execute({
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': `${id}:${encodeURIComponent(token)}`
          }
        })
          .then(data => {
            setLoading(false)
            setError('')
            setInvitationList(data)
          })
          .catch(error => {
            setLoading(false)
            if (error in MESSAGES) setError(MESSAGES[error])
            else setError(error)
          })

        return () => xhr.abort()
      }
    }
  }, [token, id, setError, setInvitationList, setLoading])

  useEffect(() => refresh(), [refresh])

  return [loading, invitations, error, refresh]
}

export default useInvitations