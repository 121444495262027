import './Input.css'

import { useCallback, useEffect, useRef, useState } from 'react'

const Input = ({ onEnd, initial = '', multiline, ...rest }) => {
  const [value, setValue] = useState(initial)
  const timerRef = useRef()
  const cancel = useCallback(() => timerRef.current && clearTimeout(timerRef.current), [timerRef])

  const onChange = useCallback(({ target }) => {
    cancel()
    setValue(target.value)
    timerRef.current = setTimeout(() => onEnd(target.value), 400)
  }, [onEnd, setValue, cancel])

  useEffect(() => cancel, [cancel])

  return multiline ? (
    <textarea value={value} onChange={onChange} {...rest} />
  ) : (
      <input value={value} onChange={onChange} {...rest} />
    )
}

export default Input
