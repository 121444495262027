import "./Confirm.css"

import React, { useCallback, useMemo, useState } from 'react'

import Input from '../input/Input'
import useConfirm from '../hooks/UseConfirm'

const ConfirmMultiple = ({ token, id, message: savedMessage = '', guests, refresh }) => {
  const [confirmed, setConfirmed] = useState(() => guests.reduce((acc, n) => ({ ...acc, [n.id]: n.confirmed }), {}))
  const [message, setMessage] = useState(savedMessage)

  const onEnd = useCallback((value) => setMessage(value), [setMessage])

  const updated = useMemo(() => ({ id, message, guests: guests.map(g => ({ ...g, confirmed: confirmed[g.id] })) }), [id, message, confirmed, guests])
  const changed = useMemo(() => message !== savedMessage ||
    guests.some(g => confirmed[g.id] !== g.confirmed), [guests, confirmed, message, savedMessage])

  const [saving, error, persist] = useConfirm(refresh, token)

  const changeConfirmed = useCallback((id) => {
    if (confirmed[id]) {
      setConfirmed({
        ...confirmed,
        [id]: false
      })
    } else {
      setConfirmed({ ...confirmed, [id]: true })
    }
  }, [confirmed, setConfirmed])

  const save = useCallback(() => persist(updated), [updated, persist])

  if (error) {
    localStorage.removeItem("surname")
  }

  return (
    <div className="confirm-box">
      <div className="content">
        <strong>Potwierdzam obecność na przyjęciu 7 sierpnia 2021 roku dla następujących osób:</strong>
      </div>
      {
        guests.map(({ id, name, surname }) => (
          <div className="decide" key={id}>
            <h2 style={{ color: confirmed[id] ? "green" : "orange" }} className="name">
              {name} {surname} <strong style={{ color: confirmed[id] ? "lightgreen" : "orange" }}>
                {confirmed[id] === null ? "?" : confirmed[id] ? "✓" : "✘"}
              </strong>
            </h2>

            <button style={{ margin: "auto 0 auto", color: confirmed[id] ? "orange" : "green" }} className="send confirm" onClick={() => changeConfirmed(id)}>
              {
                confirmed[id] ? "ZREZYGNUJ" : "POTWIERDŹ"
              }
            </button>
          </div>
        ))
      }

      <div className="message-box">
        <small>opcjonalna wiadomość/uwagi:</small>
        <Input multiline rows={8} className="message" placeholder="Zacznij pisać" initial={savedMessage} onEnd={onEnd} />
      </div>

      <div className="decide">
        <strong style={{ color: saving ? "gray" : !changed ? "green" : "red" }}>
          {
            saving && changed ? "Zapisywanie zmian..." : changed && !saving ? "Zmieniono szczegóły zaproszenia." : !changed && !saving && "Zapisano."
          }
        </strong>
        <button disabled={!changed || saving} className="send confirm" onClick={save}>ZAPISZ ZMIANY</button>
      </div>

      <div>
        {
          error && (
            <strong style={{ color: "red" }}>Coś poszło nie tak: {error}</strong>
          )
        }
      </div>
    </div>
  )
}

export default ConfirmMultiple