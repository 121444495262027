import { useState } from "react"

const Auth = ({ onSubmit, type, title = 'Podaj swoje nazwisko', placeholder = 'nazwisko' }) => {
  const [pass, setPass] = useState('')

  return (
    <div>
      <div className="search">
        <h3 style={{ margin: 0 }}>{title}</h3>
        <form className="search-input-container">
          <input
            autoFocus
            type={type}
            autoComplete="true"
            style={{ flexGrow: 1, marginRight: 10 }}
            className="searchbox"
            onKeyDown={({ key }) => { if (key === "Enter") { setPass(''); onSubmit(pass) } }}
            value={pass}
            onChange={({ target }) => setPass(target.value.toLowerCase())}
            placeholder={placeholder} />
          <button disabled={!pass} style={{ margin: "auto 0 auto", color: "green" }} className="send" onClick={() => { setPass(''); onSubmit(pass) }}>
            Dalej
          </button>
        </form>
        <small style={{ color: "gray" }}>Ten krok jest wymagany dla bezpieczeństwa.</small>
      </div>
    </div >
  )
}

export default Auth