import './index.css'

import { BrowserRouter, Route, Switch } from "react-router-dom"

import Admin from './admin/Admin'
import Invitation from './invitation/Invitation'
import React from 'react'
import ReactDOM from 'react-dom'
import Search from './search/Search'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Search />
        </Route>

        <Route exact path="/admin">
          <Admin />
        </Route>

        <Route exact path="/:id?">
          <div className="invitation-container">
            <Invitation />
          </div>
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
