import "./Search.css"

import { useCallback, useState } from "react"

import { useHistory } from "react-router"

const Search = () => {
  const [id, setId] = useState('')
  const { push } = useHistory()

  const onIdChange = useCallback((id) => push(`/${id.toLowerCase()}`), [push])

  return (
    <div className="search-container">
      <div className="search">
        <h3 style={{ margin: 0 }}>Podaj numer zaproszenia</h3>
        <div className="search-input-container">
          <input
            autoFocus
            style={{ flexGrow: 1, marginRight: 10 }}
            className="searchbox"
            onKeyDown={({ key }) => key === "Enter" && onIdChange(id)}
            value={id}
            onChange={({ target }) => setId(target.value)}
            placeholder="nr zaproszenia" />
          <button disabled={!id} style={{ margin: "auto 0 auto", color: "green" }} className="send" onClick={() => onIdChange(id)}>
            Dalej
          </button>
        </div>
        <small style={{ color: "gray" }}>Możesz go znaleźć pod kodem QR na rewersie zaproszenia.</small>
      </div>
    </div>
  )
}

export default Search