import "./Admin.css"

import { useCallback, useMemo, useState } from "react"

import Auth from "../auth/Auth"
import { useHistory } from "react-router"
import useInvitations from "../hooks/useInvitations"

const Admin = () => {
  const { push } = useHistory()
  const [id, setId] = useState(decodeURIComponent(sessionStorage.getItem("aid") || '') || '')
  const [token, setToken] = useState(decodeURIComponent(sessionStorage.getItem("atok") || '') || '')
  const [loading, invitations, error, refresh] = useInvitations(id, token)
  const list = useMemo(() => {
    let l = []

    for (const key in invitations) {
      l.push(invitations[key])
    }

    return l
  }, [invitations])

  const logout = useCallback(() => {
    sessionStorage.removeItem("aid")
    sessionStorage.removeItem("atok")
    push("/")
  }, [push])

  return (
    <div className="admin">
      {
        !id ? (
          <Auth title="podaj id" placeholder="id" onSubmit={id => {
            sessionStorage.setItem("aid", encodeURIComponent(id))
            setId(id)
          }} />
        ) : !token ? (
          <Auth type="password" title="podaj token" placeholder="token" onSubmit={token => {
            sessionStorage.setItem("atok", encodeURIComponent(token))
            setToken(token)
          }} />
        ) : loading && (
          <div className="lds-ripple"><div></div><div></div></div>
        )
      }

      {
        error && (
          <div className="error">
            <strong className="error-text">{error.message ? error.message : error}</strong>
            <button onClick={() => push("/")} className="send">
              wróć
        </button>
          </div>
        )
      }

      {
        !error && list && list.length > 0 && (
          <>
            <button style={{ margin: 10 }} className="send" onClick={refresh}>REFRESH</button>
            <button style={{ margin: 10 }} className="send" onClick={logout}>LOGOUT</button>
            {
              list.map(({ id, guests }) => (
                <div className="inv" key={id}>
                  {
                    guests.map(({ id: guestId, name, surname, confirmed }) => (
                      <div onClick={() => {
                        localStorage.setItem("surname", encodeURIComponent(surname.toLowerCase()))
                        push(`/${id}`)
                      }} className="guest" key={guestId} style={{ color: confirmed === null ? "gray" : confirmed ? "green" : "orange", cursor: "pointer" }}>
                        <h2>
                          {name} {surname}
                        </h2>
                        <span style={{ marginLeft: "auto" }}>
                          {
                            confirmed === null ? "UNKNOWN" : confirmed ? "CONFIRMED" : "NOT CONFIRMED"
                          }
                        </span>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </>
        )
      }
    </div>
  )
}

export default Admin